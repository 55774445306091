import React, { useState } from "react";
import sinImageDefault from "../../../../assets/images/sinImageDefault.jpg";
import { Carousel, Modal } from "react-bootstrap";
import left from "../../../../assets/images/icons/icon-Arrow-left-circle.svg";
import right from "../../../../assets/images/icons/icon-Arrow-right-circle.svg";
import { BodyPiercingData } from "../BudgetDataElements/BodyPiercingData";
import { BudgetMockPiercing } from "../BudgetMockPiercing";
import { useTranslation } from "react-i18next";

export const BudgetCarousel = ({ budget }) => {
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const [t] = useTranslation("global");
  let bodyPartPiercingObject = BodyPiercingData(t);
  let budgetJsonResultPiercing = BudgetMockPiercing();

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  return (
    <>
      <div className="div-info-budgets">
        {budget.budget_type === 1 && (
          <>
            <div className="div-image-info-budget">
              {budget?.media_path_budget === "null" ||
              budget?.media_path_budget === null ? (
                <img
                  className="imagen-default color-border"
                  src={sinImageDefault}
                  alt="sinImage"
                />
              ) : (
                <Carousel
                  indicators={true}
                  controls={true}
                  prevIcon={<img src={left} alt="prev" />}
                  nextIcon={<img src={right} alt="next" />}
                >
                  {budget?.media_path_budget?.split(", ").map((img, idx) => (
                    <Carousel.Item key={idx}>
                      <img
                        className="image-info-budget
                    "
                        src={img}
                        alt="First slide"
                        onClick={() => handleShow(true)}
                      />
                    </Carousel.Item>
                  ))}
                </Carousel>
              )}
            </div>
            <Modal
              show={show}
              onHide={() => setShow(false)}
              className="modal-zoom-images"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              fullscreen={fullscreen}
            >
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <Carousel
                  className="custom-carousel"
                  indicators={true}
                  controls={true}
                  prevIcon={<img src={left} alt="prev" />}
                  nextIcon={<img src={right} alt="next" />}
                >
                  {budget?.media_path_budget?.split(", ").map((img, idx) => (
                    <Carousel.Item key={idx}>
                      <img
                        className="imageCarouselBudgetModal"
                        src={img}
                        alt="First slide"
                      />
                    </Carousel.Item>
                  ))}
                </Carousel>
              </Modal.Body>
            </Modal>
          </>
        )}
        {budget.budget_type === 2 && (
          <div className="div-image-info-budget">
            {bodyPartPiercingObject?.map((data) => (
              ////////////////////////////////////
              ///Arreglo warnig en la consola, cambiando un fragment, por la etiqueta React.Fragment, a la cual añado la key
              <React.Fragment key={data.id}>
                {/* ///////////////// */}
                {data?.parts?.map((part) => (
                  ////////////////////////////////////
                  ///Arreglo warnig en la consola, cambiando un fragment, por la etiqueta React.Fragment, a la cual añado la key
                  <React.Fragment key={part.id}>
                    {/* /////////////////////////// */}
                    {/* {(budget?.body_part === data.value || */}
                    {/* // (budget?.body_part?.split(" -")[0] === data.value && */}
                    {budget?.body_part === data.value &&
                      // budget?.body_part?.split("- ")[1] === part.value)) && (
                      (budget?.body_part_site === part.value ||
                        budget?.body_part_site === null) && (
                        <div>
                          <img
                            // key={data.id}
                            className="image-info-budget"
                            src={part.image}
                            alt="First slide"
                          />
                        </div>
                      )}
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
          </div>
          // <div className="div-image-info-budget">
          //   {budget?.listMedia?.map((data) => (
          //     <>
          //       <img
          //         key={data.id}
          //         className="image-info-budget"
          //         src={data.path_media_media_budget}
          //         alt="First slide"
          //       />
          //     </>
          //   ))}
          // </div>
        )}
      </div>
    </>
  );
};
