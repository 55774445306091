import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import { Form} from "react-bootstrap";
import {Icon} from "../../../Icon/Icon";
import {updatePartialCorporation} from "../../../../helpers/InformationHelper";
import {useSelector} from "react-redux";
import {corporationIdSelector} from "../../../../redux/pageConfiguration/pcReducer";
import {Button} from "../../../Button/Button";

export const BudgetHeaderForm = ({budget, pcInfo, onChangeReplyHeaderMessagePreview}) => {
    const [t] = useTranslation("global");
    const [replyMessageIsEditable, setReplyMessageIsEditable] = useState(false);
    const corporationId = useSelector(corporationIdSelector);

    const replyMessageDefaultKey = pcInfo.reply_header_message || (budget.budget_type === 1
        ? "budget.defaultReplyMessages.studio"
        : "budget.defaultReplyMessages.piercing");

    const [replyMessagePlain, setReplyMessagePlain] = useState(t(replyMessageDefaultKey))
    const [replyMessagePreview, setReplyMessagePreview] = useState("");

    useEffect(() => {
        setReplyMessagePreview(t(replyMessagePlain, {name: budget.name, studio_name: pcInfo.studio_name}));
    }, [replyMessagePlain]);

    useEffect(() => {
        onChangeReplyHeaderMessagePreview && onChangeReplyHeaderMessagePreview(replyMessagePreview)
    }, [replyMessagePreview]);

    const onChangeReplyMessagePlain = (message) => {
        setReplyMessagePlain(message);
    };

    const resetReplyMessage = () => {
        setReplyMessagePlain(t(replyMessageDefaultKey));
    }

    const toggleEditable = () => {
        setReplyMessageIsEditable(!replyMessageIsEditable);
    }

    const saveReplyHeaderMessage = async (message) => {
        await updatePartialCorporation({
            id_corporation: corporationId,
            reply_header_message: message
        });
        toggleEditable();
    }

    return (
        <div className="div-send-budget">

            <h4 className="headingH4 mb-3 text-color">
                {t("budget.sendBudget")}
                <span className="float-end" role="button" onClick={toggleEditable}>
                    <Icon className="float-end" iconName={replyMessageIsEditable ? "close" : "edit"}/>
                </span>
            </h4>
            {
                replyMessageIsEditable &&
                (
                    <div className="mb-3">
                        <Form.Group className="mb-3">
                            <Form.Control as="textarea"
                                          rows={6}
                                          value={replyMessagePlain}
                                          onChange={(event) => {
                                              onChangeReplyMessagePlain(event.target.value)
                                          }}
                            />
                        </Form.Group>
                        <div className="d-flex justify-content-end">
                            <Button size="sm"
                                    theme="light"
                                    onClick={() => resetReplyMessage()}

                            >
                                {t("reset")}
                            </Button>
                            <Button size="sm"
                                    theme="acento"
                                    onClick={() => saveReplyHeaderMessage(replyMessagePlain)}
                            >
                                {t("save")}
                            </Button>
                        </div>

                    </div>
                )
            }
            <p className="bodyRegular color-text-secondary text-break">{replyMessagePreview}</p>
        </div>
    );
};
