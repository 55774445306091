import BudgetActions from "./budgetActions";
import AuthActions from "../auth/authActions";
import ProfileActions from "../profile/profileActions";
import PcActions from "../pageConfiguration/pcActions";
import { stylesAplication } from "../../utils/stylesAplication";
import modeJson from "../../components/Profile/components/DarkMode/darkModeJson.json";

import {
  deletedBudget,
  sendAcceptBudget,
  sendDenegedBudget,
  updateTagBudget,
  loadStore,
  loadStoreNext1000Budgets,
} from "../../helpers/budgetHelper";
import {AuthOperations} from "../auth/authOperators";

export const BudgetOperations = {
  changePage: (data, newOffset, newPageCount) => async (dispatch) => {
    dispatch(BudgetActions.changeBudgetStore(data, newOffset, newPageCount));
  },
  next1000Budgets: (id, totalAddBudget) => async (dispatch) => {
    const response = await loadStoreNext1000Budgets(id, totalAddBudget);

    dispatch(
      BudgetActions.setLoadStoreNext1000Budgets(
        response.data.budget,
        response.data.user
      )
    );
  },
  deleteBudget: (idArray, budgets) => async (dispatch) => {
    idArray?.map(async (id) => {
      await deletedBudget({ id });
      dispatch(BudgetActions.deleteBudgetStore(id, budgets));
    });
  },
  changeCheck: (id) => async (dispatch) => {
    dispatch(BudgetActions.changeValueBudgetStore(id));
  },

  acceptBudget: (data) => async (dispatch) => {
    const response = await sendAcceptBudget(data);
    if (response.status === 200) {
      let budgetsStore = data.budgets.budgets.findIndex(
        (budget) => budget.id === data.budget.id
      );
      let newBudgetsStore = [...data.budgets.budgets];
      newBudgetsStore[budgetsStore] = {
        ...newBudgetsStore[budgetsStore],
        state_budget_tag: 2,
        price_from: data.from,
        price_up: data.until,
        email_sent: data.comments,
      };
      budgetsStore = newBudgetsStore;

      let budgetsPageStore = data.budgets.budgetsPage.findIndex(
        (budget, i) => i === data.index
      );
      let newBudgetsPage = [...data.budgets.budgetsPage];
      newBudgetsPage[budgetsPageStore] = {
        ...newBudgetsPage[budgetsPageStore],
        state_budget_tag: 2,
        price_from: data.from,
        price_up: data.until,
        email_sent: data.comments,
      };
      budgetsPageStore = newBudgetsPage;
      dispatch(BudgetActions.acceptBudget(budgetsStore, budgetsPageStore));
    }
  },
  deniedBudget: (data) => async (dispatch) => {
    const response = await sendDenegedBudget(data);
    if (response.status === 200) {
      let budgetsStore = data.budgets.budgets.findIndex(
        (budget) => budget.id === data.budget.id
      );
      let newBudgetsStore = [...data.budgets.budgets];
      newBudgetsStore[budgetsStore] = {
        ...newBudgetsStore[budgetsStore],
        state_budget: "denied",
        email_sent: data.finalMessage,
      };
      budgetsStore = newBudgetsStore;

      let budgetsPageStore = data.budgets.budgetsPage.findIndex(
        (budget, i) => i === data.index
      );
      let newBudgetsPage = [...data.budgets.budgetsPage];
      newBudgetsPage[budgetsPageStore] = {
        ...newBudgetsPage[budgetsPageStore],
        state_budget: "denied",
        email_sent: data.finalMessage,
      };
      budgetsPageStore = newBudgetsPage;
      dispatch(BudgetActions.deniedBudget(budgetsStore, budgetsPageStore));
    }
  },
  updateStateTagBudget: (state_budget_tag, budgetId) => async (dispatch) => {
    await updateTagBudget(state_budget_tag, budgetId);

    dispatch(BudgetActions.updateTagBudgetStore(budgetId, state_budget_tag));
  },
  filterBudgets: (result, user) => async (dispatch) => {
    dispatch(BudgetActions.filterBudgetStore(result, user));
  },
  refreshBudgets: (id) => async (dispatch) => {
    dispatch(AuthOperations.setLoadStore(id));
  },
};
