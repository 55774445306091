import imagesEarDesign from "./assets/images/Piercing-ear-design.png";
import imagesEarCorrection from "./assets/images/Piercing-ear-correction.png";
import imagesEarLobe from "./assets/images/Piercing-ear-lobe.png";
import imagesEarDillatation from "./assets/images/Piercing-ear-dillatation.png";
import imagesEarHelix from "./assets/images/Piercing-ear-helix.png";
import imagesEarHelixForward from "./assets/images/Piercing-ear-helixforward.png";
import imagesEarScapha from "./assets/images/Piercing-ear-scapha.png";
import imagesEarTragus from "./assets/images/Piercing-ear-tragus.png";
import imagesEarAntiTragus from "./assets/images/Piercing-ear-antitragus.png";
import imagesEarDaith from "./assets/images/Piercing-ear-daith.png";
import imagesEarRook from "./assets/images/Piercing-ear-rook.png";
import imagesEarIndustrial from "./assets/images/Piercing-ear-industrial.png";
import imagesEarSurfaceTragus from "./assets/images/Piercing-ear-surfacetragus.png";
import imagesTongue from "./assets/images/Piercing-tongue.png";
import imagesTongueVertical from "./assets/images/Piercing-tongue-verticalsymmetry.png";
import imagesDolphinBite from "./assets/images/Piercing-mouth-dolphinbites.png";
import imagesNoseNostril from "./assets/images/Piercing-nose-nostril.png";
import imagesNoseSymmetryNostril from "./assets/images/Piercing-nose-nostrilsymmetry.png";
import imagesNoseSymmetrySeptum from "./assets/images/Piercing-nose-septum.png";
import imagesNosehighnostril from "./assets/images/Piercing-nose-highnostril.png";
import imagesNosehighnostrilsymmetry from "./assets/images/Piercing-nose-highnostrilsymmetry.png";
import imagesMouthLabret from "./assets/images/Piercing-mouth-labret.png";
import imagesMouthMedusa from "./assets/images/Piercing-mouth-medusa.png";
import imagesMouthVerticalLabretBottom from "./assets/images/Piercing-mouth-labret-bottom-vertical.png";
import imagesMouthVerticalLabretTop from "./assets/images/Piercing-mouth-verticallabrettop.png";
import imagesMouthSnakebite from "./assets/images/Piercing-mouth-snakebite.png";
import imagesMouthMonroe from "./assets/images/Piercing-mouth-monroe.png";
import imagesMouthAshley from "./assets/images/Piercing-mouth-ashley.png";
import imagesMouthLabretLateral from "./assets/images/Piercing-mouth-laterallabret.png";
import imagesAnotherFacialEyebrow from "./assets/images/Piercing-eyebrow.png";
import imagesAnotherFacialsymmetry from "./assets/images/Piercing-eyebrow-symmetry.png";
import imagesAnotherFacialBridge from "./assets/images/Piercing-eyebrow-bridge.png";
import imagesBellyTop from "./assets/images/Piercing-belly-top.png";
import imagesBellyBottom from "./assets/images/Piercing-belly-bottom.png";
import imagesBellySymmetry from "./assets/images/Piercing-belly-verticalsymmetry.png";
import imagesNipple from "./assets/images/Piercing-nipple.png";
import imagesNippleSymmetry from "./assets/images/Piercing-nipple-symmetry.png";
import imagesMicrodermalCheekbone from "./assets/images/Piercing-microdermal-cheekbone.png";
import imagesMicrodermalFrown from "./assets/images/Piercing-microdermal-frown.png";
import imagesMicrodermalForehead from "./assets/images/Piercing-microdermal-forehead.png";
import imagesMicrodermalChest from "./assets/images/Piercing-microdermal-chest-1.png";
import imagesMicrodermalNape from "./assets/images/Piercing-microdermal-nape.png";
import imagesMicrodermalBelly from "./assets/images/Piercing-microdermal-belly.png";
import imagesMicrodermalFemMascIntimate from "./assets/images/Piercing-microdermal-femmascintimate.png";
import imagesFemIntimateVHC from "./assets/images/Piercing-femintimate-VHC.png";
import imagesFemIntimateHCH from "./assets/images/Piercing-femintimate-HCH.png";
import imagesFemIntimateCristina from "./assets/images/Piercing-femintimate-Cristina.png";
import imagesFemIntimateLabiaMenora from "./assets/images/Piercing-femintimate-Labiamenora.png";
import imagesFemIntimateLabiaMajora from "./assets/images/Piercing-femintimate-Labiamajora.png";
import imagesMascIntimatePrinceAlbert from "./assets/images/Piercing-mascintimate-princealbert.png";
import imagesMascIntimatePrinceAlbertInvert from "./assets/images/Piercing-mascintimate-princealbertinvert.png";
import imagesMicrodermalOther from "./assets/images/Piercing-microdermal-other.png";
import imagesMascIntimateGuiche from "./assets/images/Piercing-mascintimate-guiche.png";
import imagesMascIntimateHafada from "./assets/images/Piercing-mascintimate-hafada.png";
import imagesMascIntimateFrenum from "./assets/images/Piercing-mascintimate-frenum.png";
import imagesMascIntimateLorum from "./assets/images/Piercing-mascintimate-lorum.png";

export const BodyPiercingData = (t) => {
  return [
    {
      id: "oreja",
      text: `${t("bodyPiercingData.bodyPartPiercingOreja")}`,
      value: "Oreja",
      parts: [
        {
          id: "lobulo",
          text: `${t("bodyPiercingData.bodyPartPiercingLobulo")}`,
          value: "Lóbulo",
          image: imagesEarLobe,
        },
        {
          id: "helix",
          text: `${t("bodyPiercingData.bodyPartPiercingHelix")}`,
          value: "Helix",
          image: imagesEarHelix,
        },
        {
          id: "helix forward",
          text: `${t("bodyPiercingData.bodyPartPiercingHelixFowrard")}`,
          value: "Helix forward",
          image: imagesEarHelixForward,
        },
        {
          id: "scapha",
          text: `${t("bodyPiercingData.bodyPartPiercingScapha")}`,
          value: "Scapha",
          image: imagesEarScapha,
        },
        {
          id: "tragus",
          text: `${t("bodyPiercingData.bodyPartPiercingTragus")}`,
          value: "Tragus",
          image: imagesEarTragus,
        },
        {
          id: "antitragus",
          text: `${t("bodyPiercingData.bodyPartPiercingAntitragus")}`,
          value: "Antitragus",
          image: imagesEarAntiTragus,
        },
        {
          id: "daith",
          text: `${t("bodyPiercingData.bodyPartPiercingDaith")}`,
          value: "Daith",
          image: imagesEarDaith,
        },
        {
          id: "rook",
          text: `${t("bodyPiercingData.bodyPartPiercingRoock")}`,
          value: "Rook",
          image: imagesEarRook,
        },
        {
          id: "industrial",
          text: `${t("bodyPiercingData.bodyPartPiercingIndustrial")}`,
          value: "Industrial",
          image: imagesEarIndustrial,
        },
        {
          id: "surface-tragus",
          text: `${t("bodyPiercingData.bodyPartPiercingSurfaceTragus")}`,
          value: "Surface tragus",
          image: imagesEarSurfaceTragus,
        },
        {
          id: "dilatación",
          text: `${t("bodyPiercingData.bodyPartPiercingDilatación")}`,
          value: "Dilatación",
          image: imagesEarDillatation,
        },
        {
          id: "corregir-asimetria-de-lobulo",
          text: `${t("bodyPiercingData.bodyPartPiercingLobeAsymmetries")}`,
          value: "Corregir asimetrías de lóbulo",
          image: imagesEarCorrection,
        },
        {
          id: "diseño-de-oreja",
          text: `${t("bodyPiercingData.bodyPartPiercingDesignEar")}`,
          value: "Diseño de oreja",
          image: imagesEarDesign,
        },
      ],
    },
    {
      id: "boca",
      text: `${t("bodyPiercingData.bodyPartPiercingBucal")}`,
      value: "Boca",
      parts: [
        {
          id: "lengua",
          text: `${t("bodyPiercingData.bodyPartPiercingLengua")}`,
          value: "Lengua",
          image: imagesTongue,
        },
        {
          id: "simetría-lengua-vertical",
          text: `${t(
            "bodyPiercingData.bodyPartPiercingSimetríalenguavertical"
          )}`,
          value: "Simetría lengua vertical",
          image: imagesTongueVertical,
        },
      ],
    },
    {
      id: "nariz",
      text: `${t("bodyPiercingData.bodyPartPiercingNariz")}`,
      value: "Nariz",
      parts: [
        {
          id: "nostril",
          text: `${t("bodyPiercingData.bodyPartPiercingHelixNostril")}`,
          value: "Nostril",
          image: imagesNoseNostril,
        },
        {
          id: "simetría-nostril",
          text: `${t("bodyPiercingData.bodyPartPiercingSimetríanostril")}`,
          value: "Simetría nostril",
          image: imagesNoseSymmetryNostril,
        },
        {
          id: "septum",
          text: `${t("bodyPiercingData.bodyPartPiercingSeptum")}`,
          value: "Septum",
          image: imagesNoseSymmetrySeptum,
        },
        {
          id: "high-nostril",
          text: `${t("bodyPiercingData.bodyPartPiercingHighnostril")}`,
          value: "High nostril",
          image: imagesNosehighnostril,
        },
        {
          id: "Simetría-high-nostril",
          text: `${t("bodyPiercingData.bodyPartPiercingSimetríahighnostril")}`,
          value: "Simetría high nostril",
          image: imagesNosehighnostrilsymmetry,
        },
        {
          id: "bridge",
          text: `${t("bodyPiercingData.bodyPartPiercingBridge")}`,
          value: "Bridge",
          image: imagesAnotherFacialBridge,
        },
      ],
    },
    {
      id: "labio",
      text: `${t("bodyPiercingData.bodyPartPiercingLabio")}`,
      value: "Labio",
      parts: [
        {
          id: "labret",
          text: `${t("bodyPiercingData.bodyPartPiercingLabret")}`,
          value: "Labret",
          image: imagesMouthLabret,
        },
        {
          id: "medusa",
          text: `${t("bodyPiercingData.bodyPartPiercingMedusa")}`,
          value: "Medusa",
          image: imagesMouthMedusa,
        },
        {
          id: "vertical-labret-inferior",
          text: `${t(
            "bodyPiercingData.bodyPartPiercingVerticallabretinferior"
          )}`,
          value: "Vertical labret inferior",
          image: imagesMouthVerticalLabretBottom,
        },
        {
          id: "vertical labret superior",
          text: `${t(
            "bodyPiercingData.bodyPartPiercingVerticallabretsuperior"
          )}`,
          value: "Vertical labret superior",
          image: imagesMouthVerticalLabretTop,
        },
        {
          id: "snakebite",
          text: `${t("bodyPiercingData.bodyPartPiercingSnakebite")}`,
          value: "Snakebite",
          image: imagesMouthSnakebite,
        },
        {
          id: "monroe",
          text: `${t("bodyPiercingData.bodyPartPiercingMonroe")}`,
          value: "Monroe",
          image: imagesMouthMonroe,
        },
        {
          id: "ashley",
          text: `${t("bodyPiercingData.bodyPartPiercingAshley")}`,
          value: "Ashley",
          image: imagesMouthAshley,
        },
        {
          id: "dolphin-bites",
          text: `${t("bodyPiercingData.bodyPartPiercingDolphinBites")}`,
          value: "Dolphin Bites",
          image: imagesDolphinBite,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "labret-lateral",
          text: `${t("bodyPiercingData.bodyPartPiercingLabretLateral")}`,
          value: "Labret Lateral",
          image: imagesMouthLabretLateral,
        },
      ],
    },
    {
      id: "otros-faciales",
      text: `${t("bodyPiercingData.bodyPartPiercingOtrosfaciales")}`,
      value: "Otros faciales",
      parts: [
        {
          id: "ceja",
          text: `${t("bodyPiercingData.bodyPartPiercingCeja")}`,
          value: "Ceja",
          image: imagesAnotherFacialEyebrow,
        },
        {
          id: "simetría-de-cejas",
          text: `${t("bodyPiercingData.bodyPartPiercingSimetríadecejas")}`,
          value: "Simetría de cejas",
          image: imagesAnotherFacialsymmetry,
        },
      ],
    },
    {
      id: "ombligo",
      text: `${t("bodyPiercingData.bodyPartPiercingOmbligo2")}`,
      value: "Ombligo",
      parts: [
        {
          id: "superior",
          text: `${t("bodyPiercingData.bodyPartPiercingSuperior")}`,
          value: "Superior",
          image: imagesBellyTop,
        },
        {
          id: "inferior",
          text: `${t("bodyPiercingData.bodyPartPiercingInferior")}`,
          value: "Inferior",
          image: imagesBellyBottom,
        },
        {
          id: "simetría-vertical",
          text: `${t("bodyPiercingData.bodyPartPiercingSimetríavertical")}`,
          value: "Simetría vertical",
          image: imagesBellySymmetry,
        },
      ],
    },
    {
      id: "pezón",
      text: `${t("bodyPiercingData.bodyPartPiercingPezón")}`,
      value: "Pezón",
      parts: [
        {
          id: "Pezón-masculino",
          text: `${t("bodyPiercingData.bodyPartPiercingPezónmasculino")}`,
          value: "Pezón masculino",
          image: imagesNipple,
        },
        {
          id: "Pezón-femenino",
          text: `${t("bodyPiercingData.bodyPartPiercingPezónfemenino")}`,
          value: "Pezón femenino",
          image: imagesNipple,
        },
        {
          id: "Simetría-de-pezón-masculino",
          text: `${t(
            "bodyPiercingData.bodyPartPiercingSimetríadepezónmasculino"
          )}`,
          value: "Simetría de pezón masculino",
          image: imagesNippleSymmetry,
        },
        {
          id: "Simetría-de-pezón-femenino",
          text: `${t(
            "bodyPiercingData.bodyPartPiercingSimetríadepezónfemenino"
          )}`,
          value: "Simetría de pezón femenino",
          image: imagesNippleSymmetry,
        },
      ],
    },
    {
      id: "pezón invertido",
      text: `${t("bodyPiercingData.bodyPartPiercingPezóninvertido")}`,
      value: "Pezón invertido",
      parts: [
        {
          id: "Pezón-invertido",
          text: `${t("bodyPiercingData.bodyPartPiercingPezóninvertido")}`,
          value: "Pezón invertido",
          image: imagesNipple,
        },
        {
          id: "Simetría-de-pezón-invertido",
          text: `${t(
            "bodyPiercingData.bodyPartPiercingSimetriaPezonInvertido"
          )}`,
          value: "Simetría de pezón invertido",
          image: imagesNippleSymmetry,
          type: "radio",
        },
      ],
    },
    {
      id: "intimo femenino",
      text: `${t("bodyPiercingData.bodyPartPiercingÍntimofemenino2")}`,
      value: "Íntimo femenino",
      parts: [
        {
          id: "vhc",
          text: `${t("bodyPiercingData.bodyPartPiercingVHC")}`,
          value: "VHC",
          image: imagesFemIntimateVHC,
        },
        {
          id: "hch",
          text: `${t("bodyPiercingData.bodyPartPiercingHCH")}`,
          value: "HCH",
          image: imagesFemIntimateHCH,
        },
        {
          id: "cristina",
          text: `${t("bodyPiercingData.bodyPartPiercingCristina")}`,
          value: "Cristina",
          image: imagesFemIntimateCristina,
        },
        {
          id: "labios-menores",
          text: `${t("bodyPiercingData.bodyPartPiercingLabiosmenores")}`,
          value: "Labios menores",
          image: imagesFemIntimateLabiaMenora,
        },
        {
          id: "labios-mayores",
          text: `${t("bodyPiercingData.bodyPartPiercingLabiosmayores")}`,
          value: "Labios mayores",
          image: imagesFemIntimateLabiaMajora,
        },
      ],
    },
    {
      id: "íntimo masculino",
      text: `${t("bodyPiercingData.bodyPartPiercingÍntimomasculino2")}`,
      value: "Íntimo masculino",
      parts: [
        {
          id: "príncipe-Alberto",
          text: `${t("bodyPiercingData.bodyPartPiercingPríncipeAlberto")}`,
          value: "Príncipe Alberto",
          image: imagesMascIntimatePrinceAlbert,
        },
        {
          id: "Príncipe-Alberto-invertido",
          text: `${t(
            "bodyPiercingData.bodyPartPiercingPríncipeAlbertoInvertido"
          )}`,
          value: "Príncipe Alberto invertido",
          image: imagesMascIntimatePrinceAlbertInvert,
        },
        {
          id: "guiche",
          text: `${t("bodyPiercingData.bodyPartPiercingGuiche")}`,
          value: "Guiche",
          image: imagesMascIntimateGuiche,
        },
        {
          id: "hafada",
          text: `${t("bodyPiercingData.bodyPartPiercingHafada")}`,
          value: "Hafada",
          image: imagesMascIntimateHafada,
        },
        {
          id: "frenum",
          text: `${t("bodyPiercingData.bodyPartPiercingFrenum")}`,
          value: "Frenum",
          image: imagesMascIntimateFrenum,
        },
        {
          id: "lorum",
          text: `${t("bodyPiercingData.bodyPartPiercingLorum")}`,
          value: "Lorum",
          image: imagesMascIntimateLorum,
        },
      ],
    },
    {
      id: "microdermal",
      text: `${t("bodyPiercingData.bodyPartPiercingMicrodermal")}`,
      value: "Microdermal",
      parts: [
        {
          id: "pómulo",
          text: `${t("bodyPiercingData.bodyPartPiercingPómulo")}`,
          value: "Pómulo",
          image: imagesMicrodermalCheekbone,
        },
        {
          id: "entrecejo",
          text: `${t("bodyPiercingData.bodyPartPiercingEntrecejo")}`,
          value: "Entrecejo",
          image: imagesMicrodermalForehead,
        },
        {
          id: "pecho",
          text: `${t("bodyPiercingData.bodyPartPiercingPecho")}`,
          value: "Pecho",
          image: imagesMicrodermalChest,
        },
        {
          id: "nuca",
          text: `${t("bodyPiercingData.bodyPartPiercingNuca")}`,
          value: "Nuca",
          image: imagesMicrodermalNape,
        },
        {
          id: "Ombligo ",
          text: `${t("bodyPiercingData.bodyPartPiercingOmbligo")}`,
          value: "Ombligo ",
          image: imagesMicrodermalBelly,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "pubis-femenino",
          text: `${t("bodyPiercingData.bodyPartPiercingÍntimofemenino")}`,
          value: "Pubis femenino",
          image: imagesMicrodermalFemMascIntimate,
        },
        {
          id: "pubis-masculino",
          text: `${t("bodyPiercingData.bodyPartPiercingÍntimomasculino")}`,
          value: "Pubis masculino",
          image: imagesMicrodermalFemMascIntimate,
        },
        {
          id: "otros",
          text: `${t("bodyPiercingData.bodyPartPiercingOtro2")}`,
          value: "Otros",
          image: imagesMicrodermalOther,
        },
      ],
    },
    {
      id: "otro",
      text: `${t("bodyPiercingData.bodyPartPiercingOtro2")}`,
      value: "Otro",
      parts: [
        {
          id: "otro",
          text: `${t("bodyPiercingData.bodyPartPiercingOtro2")}`,
          value: "Otro",
          image: imagesMicrodermalOther,
        },
      ],
    },
  ];
};
