import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import { AuthOperations } from "./redux/auth/authOperators";
import { PageLoading } from "./components/PageLoading/PageLoading";
import PublicRoute from "./routes/public/index";
import PrivateRoute from "./routes/private/index";
import { getAuthUser2 } from "./helpers/authHelper";
import { useSelector } from "react-redux";

import "react-phone-input-2/lib/style.css";

const App = () => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [cookies, setCookies] = useState(false);
  const [loading, setLoading] = useState(true);
  const [t] = useTranslation("global");
  useEffect(() => {
    setRedux();
    showCookies();
  }, []);

  const showCookies = async () => {
    const superCookies = localStorage.getItem("cookiesInkup");
    if (superCookies) {
      setCookies(true);
    }
  };
  useEffect(() => {
    const isFirstLoadLocalStorage = localStorage.getItem("isFirstLoad008");
 
    if (!isFirstLoadLocalStorage ) {
       localStorage.setItem("isFirstLoad008", "true");
       window.location.reload(true);
    }
  }, []);

  const setRedux = async () => {
    const tokenAuth = getAuthUser2();
    if (!!tokenAuth) {
      dispatch(AuthOperations.setLoadStore(tokenAuth.userObj.id));
    }
  };

  return (
    <>
      {loading === true ? (
        <PageLoading setLoading={setLoading} />
      ) : (
        <BrowserRouter>
          {!state.auth?.token || state.auth?.token?.length === 0 ? (
            <PublicRoute />
          ) : (
            <PrivateRoute
              state={state}
              setCookies={setCookies}
              cookies={cookies}
            />
          )}
        </BrowserRouter>
      )}
    </>
  );
};

export default App;
