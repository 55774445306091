import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { sendSuggestion } from "../../helpers/suggestionHelper";
import { AlertConfirmation } from "../Alerts/AlertConfirmation";
import { Button, Form, Col, FloatingLabel } from "react-bootstrap";
import "../../assets/styles/css/index.css";
import imgTattooMachine from "../../assets/images/imgTattooMachine.png";

export const Suggestions = ({ user }) => {
  const titleRef = useRef(null);
  const descriptionRef = useRef(null);
  const [show, setShow] = useState(false);

  const onFormSubmit = async (e) => {
    e.preventDefault();
    const title = titleRef.current.value;
    const description = descriptionRef.current.value;
    const idUser = user.profile.id;
    await sendSuggestion({ idUser, title, description });
    setShow(true);
    e.target.reset();
    setTimeout(() => {
      setShow(false);
    }, 5000);
  };

  const [t] = useTranslation("global");

  return (
    <Col translate="no" className="p-4">
      <h2 className="fs-5 pb-3 titleSuggestion headingH2">
        {t("suggestion.title")}
      </h2>
      <div className="centralBox lightBackground">
        <div className="pe-5">
          <p className="bodyRegular">
            {t("suggestion.information")}
            <br />
            {t("suggestion.information2")}
          </p>
          <Form onSubmit={onFormSubmit}>
            <Form.Group
              className="mb-3 pt-4 pb-3 bodySubcontentBold"
              controlId="formBasicName"
            >
              <Form.Label>{t("suggestion.affair")}</Form.Label>
              <Form.Control
                autoComplete="nope"
                type="text"
                ref={titleRef}
                required
                placeholder={t("suggestion.boxAffair")}
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
              />
            </Form.Group>

            {/* <FloatingLabel
              controlId="floatingTextarea"
              // label={t("suggestion.boxSuggestion")}
              className="mb-3 bodyRegular"
            > */}
            <Form.Control
              autoComplete="nope"
              as="textarea"
              ref={descriptionRef}
              required
              style={{ height: "100px" }}
              placeholder={t("suggestion.boxAffair")}
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e) => {
                e.preventDefault();
                return false;
              }}
            />
            {/* </FloatingLabel> */}

            <Button
              type="submit"
              className="d-block mt-4 rounded-pill purpleLargeButton bodyDetailsBold"
            >
              {t("suggestion.send")}
            </Button>
          </Form>
        </div>
        <div>
          <img
            src={imgTattooMachine}
            className="ps-5 imgTattooMachine"
            alt=""
          />
        </div>
      </div>
      <AlertConfirmation text={"Solicitud enviada"} show={show} />
    </Col>
  );
};
