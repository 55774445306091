import SizeTattooData from "../components/BudgetDataElements/SizeTattooData";
import ColorTattooData from "../components/BudgetDataElements/ColorTattooData";
import { BodyTattooData } from "../components/BudgetDataElements/BodyPartTattooData";
import { BodyPiercingData } from "../components/BudgetDataElements/BodyPiercingData";
import { TimeTattooData } from "../components/BudgetDataElements/TimeTattooData";

import { SkinTattooData } from "../components/BudgetDataElements/SkinTattooData";

export const textMessage = (
  messageWhatsAppMail,
  budget,
  user,
  t,
  from,
  until,
  comments,
  asterisk,
  currency,
  replyHeaderMessage
) => {
  const budgetArray = [budget];
  const [budgetObject] = budgetArray;
  const {
    name,
    body_part,
    budget_color,
    skintone,
    description,
    city,
    time,
    employed,
    media_path_budget,
    type_jewelry,
    old_client,
    body_part_site,
  } = budgetObject;

  let sizeArrayObject = SizeTattooData(t);
  let coloArrayObject = ColorTattooData(t);
  let timeArrayObject = TimeTattooData(t);
  let skinArrayObject = SkinTattooData(t);

  let bodyPartArrayObject =
    user.id_rol !== 4 ? BodyTattooData(t) : BodyPiercingData(t);

  let resultColor;
  coloArrayObject.map((data) => {
    budget_color === data.value && (resultColor = data.text);
  });

  let resultSkin;
  skinArrayObject.map((data) => {
    skintone === data.value && (resultSkin = data.imageMessage);
  });

  let resultSize;
  sizeArrayObject.map((data) => {
    budget.size === data.value &&
      (resultSize = user?.check_cm === 0 ? data?.textSize : data?.text);
  });

  let resultBody;
  bodyPartArrayObject.map((data) => {
    body_part === data.value && (resultBody = data.text);
  });

  let resultBodyPart = "";
  bodyPartArrayObject.find((data) => {
    const part = data?.parts?.find((part) => body_part_site === part?.value);
    if (part) {
      resultBodyPart = part.text;
      return true;
    }
    return false;
  });

  let resultTime;
  timeArrayObject.map(
    (data) => time === data?.value && (resultTime = data?.text)
  );

  let client;
  let ast = asterisk ? "*" : "";

  if (old_client === 1 || old_client === "1") {
    client = t("piercingpreviouslyData.piercingYes");
  }
  if (old_client === 0 || old_client === "0") {
    client = t("piercingpreviouslyData.piercingNo");
  }

  let text = "";
  let finalResult = "";

  if (media_path_budget !== null) {
    if (messageWhatsAppMail) {
      const modifiedUrls =
        media_path_budget &&
        media_path_budget
          ?.split(", ")
          .map((obj, index) => {
            const comma = index < media_path_budget.length - 1 ? ",%0a\n" : "";
            return obj + comma + "%0a";
          })
          .join("") + "%0a";
      finalResult = `${ast}${t("budget.images")}${ast}: %0a${modifiedUrls}`;
    } else {
      const modifiedUrls =
        media_path_budget &&
        media_path_budget
          ?.split(", ")
          .map((obj, index) => {
            const url = obj
              .replace("?alt", "%3Falt")
              .replace("=media", "%3Dmedia");
            const comma = index < media_path_budget.length - 1 ? ",%0a" : "";
            return url + comma;
          })
          .join("") + "%0a";
      finalResult = `${ast}${t("budget.images")}${ast}: %0a${modifiedUrls}`;
    }
  }

  if (user) {
    text =
      `${replyHeaderMessage}%0a` +
      `${
        `${
          from === "" || from === null
            ? ""
            : `${ast}${t("budget.replyBudgetStudy3")}: ${t(
                "budget.fromResp"
              )} ${from}${currency.symbol}${ast}`
        }` +
        `${
          from === "" || from === null
            ? until === "" || until !== null
              ? `${ast}${t("budget.replyBudgetStudy3")}${ast}:`
              : ""
            : ""
        }` +
        `${
          until === "" || until === null
            ? ""
            : ` ${ast}${t("budget.toResp")} ${until}${currency.symbol}${ast}`
        }`
      }` +
      "%0a" +
      "%0a" +
      `${
        comments
          ? comments + "%0a%0a"
          : "" || budget.email_sent
          ? budget.email_sent + "%0a%0a"
          : ""
      }` +
      `${ast}${t("budget.summaryRequest")}${ast}: ` +
      "%0a" +
      "%0a";
  }
  const keyMapping = {
    size: `${ast}${t("budget.size")}${ast}: ` + `${resultSize}`,
    description: `${ast}${t("formChat.infoDescription")}${ast} ` + description,
    body_part:
      `${ast}${t("budget.bodyPart")}${ast}: ` +
      resultBody +
      `${body_part_site !== null ? " - " : ""}` +
      resultBodyPart,
    budget_color: `${ast}${t("budget.color")}${ast}: ` + resultColor,
    skintone: `${ast}${t("budget.skin_tone")}${ast}:%0a` + resultSkin,
    city: `${ast}${t("budget.city")}${ast}: ` + city,
    time: `${ast}${t("budget.timeAvailability")}${ast}: ` + resultTime,
    employed: `${ast}${t("budget.artist")}${ast}: ` + employed,
    type_jewelry: `${ast}${t("budget.jewelry")}${ast}: ` + type_jewelry,
    old_client: `${ast}${t("budget.client")}${ast}: ` + client,
  };

  if (description === "") {
    delete keyMapping.description;
  }

  text += finalResult += Object.keys(budgetObject)
    .filter(
      (key) => keyMapping[key] !== undefined && budgetObject[key] !== null
    )
    .map((key) => `${keyMapping[key]} `)
    .join("%0a\n");
  return text;
};
