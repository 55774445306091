import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Card, Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { firebaseImage } from "../../../../../../utils/firebaseImage";
import { PCOperations } from "../../../../../../redux/pageConfiguration/pcOperators";
import { ProfileOperations } from "../../../../../../redux/profile/profileOperators";

import Spinner from "react-bootstrap/Spinner";

export const PCLogo = ({ id, user, pCInfo }) => {
  const [t] = useTranslation("global");
  const dispatch = useDispatch();
  const [image, setImage] = useState(user?.imageProfile?.path_media);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef();
  let resultInitialName = pCInfo.PCInformation.studio_name
    ?.charAt(0)
    .toUpperCase();

  useEffect(() => {
    if (pCInfo.pCLogo?.path_media) {
      dispatch(PCOperations.checkSaveLogoAction({ checkLogo: true }));
    }
  }, []);

  const uploadImage = async (e) => {
    setLoading(true);
    let enlaceUrl = await firebaseImage(e.target.files[0], id);
    if (enlaceUrl) {
      dispatch(
        ProfileOperations.updateImageProfile({ enlaceUrl: enlaceUrl, id })
      );
      setLoading(false);
    }
    setImage(enlaceUrl);
  };

  const deleteImage = async (e) => {
    dispatch(ProfileOperations.deleteImageProfileAction({ id }));
    setImage(null);
  };
  const inputRefWithButton = () => {
    inputRef.current.click();
  };

  return (
    <Form>
      <Form.Group>
        <div className="div-logo-buttons">
          <div>
            {!image |
            (image === null) |
            (image === "null") |
            (image === "undefined") ? (
              <div className="image-logo-upload color-border">
                {loading === true ? (
                  <Spinner animation="border color-position-spinner" />
                ) : (
                  <>
                    <div className="initial-name">
                      <p className="mb-0 text-color">{resultInitialName}</p>
                    </div>
                    <input type="file" onChange={uploadImage} ref={inputRef} />
                  </>
                )}
              </div>
            ) : (
              <>
                <div>
                  <div style={{ border: "none" }}>
                    <Card.Img
                      variant="top"
                      className="align-start logo-image"
                      src={image}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="div-buttons-logo">
            <Button
              className="bottonConfigAssistant bodyDetailsBold bg-button color-text-button mb-2"
              onClick={inputRefWithButton}
            >
              {t("pageConfiguration.buttonUploadImage")}
            </Button>
            <Button
              className="bottonDeleteConfigAssistant bodyDetailsBold bg-button color-text-button"
              onClick={deleteImage}
            >
              {t("pageConfiguration.buttonDelete")}
            </Button>
          </div>
        </div>
      </Form.Group>
    </Form>
  );
};
