import React from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Container, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import iconoStripe from "../../../../assets/images/icon-stripe.svg";
import iconoStripePlans from "../../../../assets/images/icon-stripe-plans.svg";
import iconCheckGreen from "../../../../assets/images/icon-Check-Green.svg";
import iconCheckBlack from "../../../../assets/images/icon-Check-black.svg";
import iconCheckBlack2 from "../../../../assets/images/icon-X-black.svg";

export const Stripe = ({ data }) => {
  const [t] = useTranslation("global");

  return (
    <div className="text-color">
      <p className="titleStripe">{t("stripe.title")}</p>
      <div className="divButtonStripe">
        <Button
          className="bottonStripe bg-button color-text-button"
          type="submit"
        >
          <Link className="linkStripe color-text-button" to="/stripe">
            <div className="divStripeLink">
              <p className="textButtonLeft">{t("stripe.ButtonSubscription")}</p>
              <img
                className={data?.profile?.theme === 1 ? "filter-img" : ""}
                src={iconoStripe}
                alt="Icono Stripe"
              ></img>
            </div>
          </Link>
        </Button>
      </div>
    </div>
  );
};

export const StripeLinks = () => {
  const [t] = useTranslation("global");
  const linkProfesional = "https://buy.stripe.com/bIYdUheOz9q44LufZg";
  const linkpersonalised = "https://buy.stripe.com/6oEbM90XJeKo4LudR2";
  const linkpersonalisedWa =
    "https://api.whatsapp.com/send/?phone=%2B34627778062&text=Hola%2C+queria+información+sobre+el+plan+personalizado+de+Inkup&type=phone_number&app_absent=0";

  return (
    <Container
      fluid
      translate="no"
      className="containerMyAccount containerStripe"
    >
      <Row>
        <Col>
          <div className="divTextMyAccount text-color">
            <h2 className="headingH2">{t("stripe.title2")}</h2>
          </div>
        </Col>
        <hr></hr>
      </Row>
      <Row>
        <Col className="colCentralBoxProfile">
          <div className="centralBoxProfile ps-0 pe-0">
            <p className="title-stripe-plans bg-badge color-text-white">
              {t("stripe.title")}
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="colCentralBoxProfile">
          <div className="centralBoxProfile bg-container lightBackground">
            <div className="card-stripe">
              <div className="card-body ps-0 pb-0 pt-2">
                <h5 className="color-text">{t("stripe.initialPlan")}</h5>
                {/* <h6 className="card-subtitle mb-4 color-text-secondary">
                  {t("stripe.initialPlanText")}
                </h6> */}
                <p className="card-text price-stripe color-text">0€</p>
              </div>
              <div className="card-img-stripe">
                <img src={iconoStripePlans} alt="Icono Stripe"></img>
              </div>
            </div>

            <div className="icon-check-text mt-3">
              <img src={iconCheckGreen} alt="Icono check"></img>
              <p className="color-text-secondary">
                {t("stripe.limitRequestInitialPlan")}
              </p>
            </div>
          </div>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col className="colCentralBoxProfile">
          <div className="centralBoxProfile bg-container lightBackground">
            {/* /////PROMOCION */}
            {/* <div className="promotionalPrice">
              <p className="color-text-white mb-0">-25%</p>
            </div> */}
            {/* ///// */}
            <div className="card-stripe">
              <div className="card-body ps-0 pb-0">
                <h5 className="color-text">{t("stripe.professionalPlan")}</h5>
                {/* <h6 className="card-subtitle mb-4 color-text-secondary">
                  {t("stripe.professionalPlanText")}
                </h6> */}
              </div>
              <div className="card-img-stripe">
                <img src={iconoStripePlans} alt="Icono Stripe"></img>
              </div>
            </div>
            <div className="card-body ps-0 pb-0 pt-0">
              {/* <span className="card-text price-stripe color-text">29€</span> */}

              {/* PRECIO EN PROMOCION NO BORRAR ESTE CODIGO*/}
              {/* <span className="card-text price-stripe color-text price-plan">
                36€
              </span> */}
              <span className="card-text price-stripe color-text">29€</span>

              <span className="color-text-secondary ps-2 span-text">
                {t("stripe.textPrice")}
              </span>
              <div className="icon-check-text mt-3">
                <img src={iconCheckGreen} alt="Icono check"></img>
                <p className="color-text-secondary">
                  {t("stripe.limitRequestProfessionalPlan")}
                </p>
              </div>
              <div className="icon-check-text mt-3">
                <img src={iconCheckBlack} alt="Icono check"></img>
                <p className="color-text-secondary">
                  {t("stripe.visualCustomisation")}
                </p>
              </div>
              <div className="icon-check-text mt-3">
                <img src={iconCheckBlack} alt="Icono check"></img>
                <p className="color-text-secondary">
                  {t("stripe.visualCustomisation2")}
                </p>
              </div>
            </div>
            <div className="div-button-stripe-plan">
              <Button className="bottonStripePlan bg-button" type="submit">
                <a
                  className="color-text-button"
                  href={linkProfesional}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("stripe.ButtonSubscriptionPlans")}
                </a>
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col className="colCentralBoxProfile">
          <div className="centralBoxProfile bg-container lightBackground border-recommended">
            <div className="recommendedPrice">
              <p className="color-text-white mb-0">{t("stripe.recommended")}</p>
            </div>
            <div className="card-stripe">
              <div className="card-body ps-0 pb-0">
                <h5 className="color-text">{t("stripe.top")}</h5>
                {/* <h6 className="card-subtitle mb-4 color-text-secondary">
                  {t("stripe.personalisedPlanText")}
                </h6> */}
              </div>
              <div className="card-img-stripe">
                <img src={iconoStripePlans} alt="Icono Stripe"></img>
              </div>
            </div>
            <div className="card-body ps-0 pb-0 pt-0">
              <span className="card-text price-stripe color-text">49€</span>
              <span className="color-text-secondary ps-2 span-text">
                {t("stripe.textPrice")}
              </span>
              <div className="icon-check-text mt-3">
                <img src={iconCheckGreen} alt="Icono check"></img>
                <p className="color-text-secondary">
                  {t("stripe.4000Requests")}
                </p>
              </div>
              <div className="icon-check-text mt-3">
                <img src={iconCheckGreen} alt="Icono check"></img>
                <p className="color-text-secondary">
                  {t("stripe.visualCustomisation")}
                </p>
              </div>
              <div className="icon-check-text mt-3">
                <img src={iconCheckBlack} alt="Icono check"></img>
                <p className="color-text-secondary">
                  {t("stripe.visualCustomisation2")}
                </p>
              </div>
            </div>
            <div className="div-button-stripe-plan">
              <Button
                className="bottonStripePlan bg-button color-text-button"
                type="submit"
              >
                <a
                  className="color-text-button"
                  href={linkpersonalised}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("stripe.ButtonSubscriptionPlans")}
                </a>
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col className="colCentralBoxProfile">
          <div className="centralBoxProfile bg-container lightBackground border">
            <div className="recommendedPrice">
              <p className="color-text-white mb-0">{t("stripe.recommended")}</p>
            </div>
            <div className="card-stripe">
              <div className="card-body ps-0 pb-0">
                <h5 className="color-text">{t("stripe.personalisedPlan")}</h5>
                {/* <h6 className="card-subtitle mb-4 color-text-secondary">
                  {t("stripe.personalisedPlanText")}
                </h6> */}
              </div>
              <div className="card-img-stripe">
                <img src={iconoStripePlans} alt="Icono Stripe"></img>
              </div>
            </div>
            <div className="card-body ps-0 pb-0 pt-0">
              <h5 className="color-text">{t("stripe.consultPrice")}</h5>

              <div className="icon-check-text mt-3">
                <img src={iconCheckGreen} alt="Icono check"></img>
                <p className="color-text-secondary">
                  {t("stripe.unlimitedRequests")}
                </p>
              </div>
              <div className="icon-check-text mt-3">
                <img src={iconCheckGreen} alt="Icono check"></img>
                <p className="color-text-secondary">
                  {t("stripe.visualCustomisation")}
                </p>
              </div>
              <div className="icon-check-text mt-3">
                <img src={iconCheckGreen} alt="Icono check"></img>
                <p className="color-text-secondary">
                  {t("stripe.visualCustomisation2")}
                </p>
              </div>
            </div>
            <div className="div-button-stripe-plan">
              <Button
                className="bottonStripePlan bg-button color-text-button"
                type="submit"
              >
                <a
                  className="color-text-button"
                  href={linkpersonalisedWa}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("stripe.ButtonSubscriptionPlans2")}
                </a>
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
