import {FormControl, InputGroup} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import React, {useState} from "react";

export const AmountInput = ({
                                value,
                                currencies,
                                currencySelected,
                                disableCurrency = false,
                                onCurrencyChange,
                                onValueChange
                            }) => {
    const [_currencySelected, _setCurrencySelected] = useState(currencySelected);
    const [_value, _setValue] = useState(value || 0);

    const changeCurrency = (currency) => {
        _setCurrencySelected(currency);
        currencySelected = currency;
        onCurrencyChange && onCurrencyChange(currency);
    }

    const changeValue = (amount) => {
        _setValue(amount)
        onValueChange && onValueChange(amount);
    }

    return (
        <InputGroup>
            <FormControl
                autoComplete="nope"
                className="bg-input color-text-secondary no-spinners"
                value={_value}
                onChange={(e) => changeValue(e.target.value)}
                type="number"
                required
            />

            <Dropdown>
                <Dropdown.Toggle disabled={disableCurrency} variant="secondary">
                    {currencySelected.symbol}
                </Dropdown.Toggle>

                <Dropdown.Menu >
                    {
                        currencies?.map((currency) => (
                            <Dropdown.Item key={currency.iso}
                                           onClick={() => changeCurrency(currency)}>
                                {`${currency.iso} (${currency.symbol})`}
                            </Dropdown.Item>
                        ))
                    }
                </Dropdown.Menu>
            </Dropdown>
        </InputGroup>
    )
}
