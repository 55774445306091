import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Accordion } from "react-bootstrap";
import { Pagination } from "./Pagination/Pagination";
import { BudgetOperations } from "../../../redux/budget/budgetOperators";
import { BudgetMock } from "./BudgetMock";
import { BudgetMockPiercing } from "./BudgetMockPiercing";
import { BodyPiercingData } from "./BudgetDataElements/BodyPiercingData";
import eyeOff from "../../../assets/images/icons/icon-eye-off.svg";
import { BudgetButtons } from "./BudgetButtons";
import { BudgetSearch } from "./BudgetSearch";
import { BudgetHeader } from "./BudgetHeader";
import { BudgetRow } from "./BudgetRow/BudgetRow";

export const BudgetAccordion = ({ userProfile, budgets, pcInfo }) => {
  const dispatch = useDispatch();
  const [t] = useTranslation("global");
  const [currentItems2, setCurrentItems2] = useState(null);
  const [value, setValue] = useState("phone_budget");
  const [input, setInput] = useState("");
  const [idBudgets, setIdBudgets] = useState([]);
  const [selectedIdx, setSelectedIdx] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (boolean) => setShow(boolean);
  const [eventKeyStates, setEventKeyStates] = useState({});
  const [activeEventKey, setActiveEventKey] = useState(null);
  let textButton = `${t("budgetAccordion.ButtonCollapsed")}`;
  let textButton2 = `${t("budgetAccordion.ButtonCollapsed2")}`;
  let budgetJsonResultTattoo = BudgetMock();
  let budgetJsonResultPiercing = BudgetMockPiercing();
  let bodyPartPiercingObject = BodyPiercingData(t);
  let budgetJsonResult =
    userProfile.profile.id_rol !== 4
      ? budgetJsonResultTattoo
      : budgetJsonResultPiercing;
  const hadleSearch = (e) => {
    let text = e.target.value;
    setInput(text);

    if (text.length > 0) {
      var result = budgets.budgetsAll.filter((obj) => {
        if (value === "phone_budget") {
          return obj.phone_budget?.toLowerCase().includes(text.toLowerCase());
        }
        if (value === "name") {
          return obj.name?.toLowerCase().includes(text.toLowerCase());
        }
        if (value === "employed") {
          return obj.employed?.toLowerCase().includes(text.toLowerCase());
        }
      });
      budgets.itemOffset = 0;
      dispatch(BudgetOperations.filterBudgets(result, userProfile));
    } else {
      dispatch(BudgetOperations.filterBudgets(budgets.budgetsAll, userProfile));
    }
  };

  const handleChange = (e) => {
    setValue(e.target.value);
    setInput("");
    hadleSearch({ target: { value: "" } });
  };

  const handleClick = () => {
    const rows = budgets.budgets.filter((li) => !li.checked);
    dispatch(BudgetOperations.deleteBudget(idBudgets, rows));
    setIdBudgets([]);
    setCurrentItems2(rows);
    setShow(false);
  };

  const refreshBudgets = () => {
    window.location.replace("");
  };

  const changeTextButton = (eventKey) => {
    if (activeEventKey !== eventKey) {
      setEventKeyStates((prevState) => {
        const updatedState = { ...prevState };
        if (prevState[activeEventKey]) {
          updatedState[activeEventKey] = false;
        }
        updatedState[eventKey] = true;
        return updatedState;
      });
      setActiveEventKey(eventKey);
    } else {
      setEventKeyStates((prevState) => ({
        ...prevState,
        [eventKey]: !prevState[eventKey],
      }));
    }
  };

  let itemsToMap;
  if (budgets?.budgetsAll?.length === 0) {
    itemsToMap = budgetJsonResult;
  } else {
    itemsToMap = currentItems2;
  }

  return (
    <>
      <BudgetButtons
        refreshBudgets={refreshBudgets}
        budgets={budgets}
        pcInfo={pcInfo}
        idBudgets={idBudgets}
        handleShow={handleShow}
        userProfile={userProfile}
      />
      <BudgetSearch
        input={input}
        hadleSearch={hadleSearch}
        value={value}
        handleChange={handleChange}
      />

      <Accordion className="accordion-budgets">
        {itemsToMap?.map((budget, idx) => (
          <div key={budget.id}>
            <Accordion.Item
              eventKey={idx}
              id={budget.assigned_by}
              className={
                budget.disabled
                  ? "input2 grey-budget style-acordion-buttons"
                  : "style-acordion-buttons"
              }
            >
              <BudgetHeader
                userProfile={userProfile}
                budget={budget}
                setIdBudgets={setIdBudgets}
                idBudgets={idBudgets}
                show={show}
                setShow={setShow}
                idx={idx}
                textButton={textButton}
                textButton2={textButton2}
                handleClose={handleClose}
                handleClick={handleClick}
                bodyPartPiercingObject={bodyPartPiercingObject}
                eventKeyStates={eventKeyStates}
                changeTextButton={() => changeTextButton(idx)}
                setSelectedIdx={setSelectedIdx}
              />

              {budget.disabled && (
                <img
                  className="input-icon"
                  alt="imagen-disabled"
                  src={eyeOff}
                />
              )}
              <Accordion.Body
                className="div-accordion-body-budgets bg-container"
                eventKey={idx}
              >
                <div className="div-actions-budget eeee">
                  <BudgetRow
                    budgets={budgets}
                    budget={budget}
                    theme={userProfile.profile.theme}
                    index={idx}
                    pcInfo={pcInfo}
                    idRol={userProfile.profile.id_rol}
                    idUser={userProfile.profile.id}
                    changeTextButton={() => changeTextButton(idx)}
                  />
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </div>
        ))}
        <Pagination
          budgets={budgets}
          currentItems2={currentItems2}
          setCurrentItems2={setCurrentItems2}
          selectedIdx={selectedIdx}
          setSelectedIdx={setSelectedIdx}
        />
      </Accordion>
    </>
  );
};
