import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import logoTypeWhite from "../../assets/images/logo-Type-White.svg";
import "./PageLoading.css";

export const PageLoading = ({ setLoading }) => {
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  return (
    <div className="divPageLoading">
      <img
        className="logotypePageLoading"
        src={logoTypeWhite}
        alt="Icono Inkup"
      ></img>
      <div>
        {" "}
        <Spinner animation="border colorSpinner mt-5" role="status">
          <span className=" visually-hidden "></span>
        </Spinner>
      </div>
    </div>
  );
};
